import { Settings } from "../../config/config";
import { windowGlobal } from "../../utils/window-global";
import { languageCodes } from "../../config/languages";

export const api = (url, options = {}) => {
    const finalUrl = `${Settings.apiHost}${url}`;

    const language = windowGlobal ? (localStorage.getItem('language') ?? languageCodes.EN) : languageCodes.EN

    const defaultHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-lang-code': language
    }
    const finalOptions = {
        ...options,
        headers: {
            ...options.headers,
            ...defaultHeaders
        }
    }

    return fetch(finalUrl, finalOptions);
};

export const submitSp = async ({ email, referral_id, referral_type }) => {
    const response = await api('/api/sp/auth/track-referral', {
        method: 'POST',
        body: JSON.stringify({ email, referral_id, referral_type })
    })

    return response.json()
}

export const forgotPassword = async ({ email, userType }) => {
    const response = await api(`/api/${userType}/auth/forgot_password`, {
        method: 'POST',
        body: JSON.stringify({
            email
        })
    })

    return response.json()
}