import React, { useState } from 'react'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";
import * as Yup from 'yup'

import warningIcon from "../content/assets/warning.svg";
import { submitSp } from "../../../services/api/api";
import { extractQueryParameter } from "../../../services/extract-query-parameter/extract-query-parameter";
import { Link } from '../../common/link'
import arrowRightIcon from './assets/arrow-right.svg'
import {DownloadSpAppPopup} from "../../common/download-sp-app-popup";

export const SignupForm = (
    {
        formTitle,
        formSubtitle,
        emailRequiredMessage,
        emailTitle,
        emailAlreadyRegisteredText,
        resetPasswordText,
        downloadAppTitle,
        downloadAppSubtitle
    }) => {
    const { t } = useTranslation()

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isNotUniqueEmail, setIsNotUniqueEmail] = useState(false)

    const submitSpMutation = useMutation(submitSp, {
        onSuccess: data => {
            if (data.status === 'fail') {
                const errors = data.errors ?? {}
                if (errors.hasOwnProperty("email")) {
                    setIsNotUniqueEmail(true)
                }

                toast.error(`${Object.values(errors).join(', ')}`)
                return
            }

            setFormSubmitted(true)
        }
    })

    return (
        <div className="signup__form" style={isNotUniqueEmail ? { bottom: '-410px' } : {}}>
            <div className="signup__form__container">
                <span className="signup__form__title">{t(formTitle)}</span>
                <span className="signup__form__subtitle">{t(formSubtitle)}</span>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object().shape({ email: Yup.string().required(t(emailRequiredMessage)) })}
                    onSubmit={(submitData) => {
                        submitSpMutation.mutate({
                            email: submitData.email,
                            referral_id: extractQueryParameter('r'),
                            referral_type: extractQueryParameter('t')
                        })
                    }}>
                    {formikProps => (
                        <Form>
                            <div className="signup__form__rows">
                                <div className="signup__form__row">
                                    <div className="signup__form__row__inputs">
                                        <input
                                            className={`signup__form__input ${(formikProps.errors.email && formikProps.touched.email) || isNotUniqueEmail ? ' signup__form__input--error' : ''}`}
                                            placeholder={t(emailTitle)}
                                            type="email"
                                            name="email"
                                            onChange={(e) => {
                                                formikProps.handleChange(e)
                                                setIsNotUniqueEmail(false)
                                            }}
                                            onBlur={formikProps.handleBlur}
                                            value={formikProps.values.email}
                                        />
                                    </div>
                                    {formikProps.errors.email && formikProps.touched.email && (
                                        <div className="signup__form__row__errors">
                                            <span>{formikProps.errors.email}</span>
                                        </div>
                                    )}
                                    {isNotUniqueEmail && formikProps.touched.email && (
                                        <div className="signup__form__row__errors--email">
                                            <p>{t(emailAlreadyRegisteredText)}</p>
                                            <p>
                                                <Link to="/forgot-password?userType=sp">{t(resetPasswordText)}</Link>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="signup__form__row">
                                    <button
                                        type="submit"
                                        className="signup__form__button"
                                    >
                                        <img src={arrowRightIcon} />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <DownloadSpAppPopup
                isVisible={formSubmitted}
                setVisible={(value) => {setFormSubmitted(value)}}
                title={t(downloadAppTitle)}
                subtitle={t(downloadAppSubtitle)}
            />
        </div>
    )
}