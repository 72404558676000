import * as React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { Link } from '../link'
import { countryCodes } from '../../../config/countries'
import './index.sass'

export function DownloadSpApp({
  popupTitle,
  popupSubtitle,
  qrTitle,
  currentCountryCode,
  qrDescription,
  storeLinks,
}) {
  return (
    <div className="download-sp-app">
      <div className="download-sp-app__download-app">
        <div className="download-sp-app__download-app__left">
          <span className="download-sp-app__download-app__title">{popupTitle}</span>
          <span className="download-sp-app__download-app__subtitle">{popupSubtitle}</span>
          <div className="download-sp-app__download-app__qr-container">
            {currentCountryCode === countryCodes.UAE ? (
                <StaticImage
                    src="../../../assets/download-uae-app-qr.png"
                    alt={qrTitle}
                    className="download-sp-app__download-app__qr"
                />
            ) : currentCountryCode === countryCodes.GREECE ? (
                <StaticImage
                    src="../../../assets/download-gr-app-qr.png"
                    alt={qrTitle}
                    className="download-sp-app__download-app__qr"
                />
            ) : (
                <StaticImage
                    src="../../../assets/download-sa-app-qr.png"
                    alt={qrTitle}
                    className="download-sp-app__download-app__qr"
                />
            )}
            <span className="download-sp-app__download-app__qr-description">{qrDescription}</span>
          </div>
          <div className="download-sp-app__download-app__stores">
            {storeLinks.map(storeLinkItem => (
              <Link
                to={storeLinkItem.link}
                key={storeLinkItem.title}
                className="download-sp-app__download-app__stores__img"
              >
                <GatsbyImage
                  image={getImage(storeLinkItem.store_image)}
                  alt={storeLinkItem.title}
                  className="download-sp-app__download-app__stores__img"
                />
              </Link>
            ))}
          </div>
        </div>
        <div className="download-sp-app__download-app__right">
          {currentCountryCode === countryCodes.UAE ? (
            <StaticImage src="../../../assets/phone-rotated-uae.png" />
          ) : (
            <StaticImage src="../../../assets/phone-rotated-gr.png" />
          )}
        </div>
      </div>
    </div>
  )
}