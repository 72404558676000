import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../../layouts'
import { DownloadSpApp } from '../download-sp-app'
import { Popup } from '../popup'

export function DownloadSpAppPopup({ isVisible, setVisible, title, subtitle }) {
  const { country } = React.useContext(AppContext)
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "get-started"}}}) {
        nodes {
          frontmatter {
            slug
            country
            get_started_section {
              popup_title
              qr_title
              qr_description
              store_links {
                title
                link
                store_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { t } = useTranslation();

  const {
    popup_title,
    qr_title,
    qr_description,
    store_links,
  } = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.get_started_section

  return (
    <Popup
      visible={isVisible}
      onClose={() => { setVisible(false) }}
    >
      <DownloadSpApp
        popupTitle={title ?? t(popup_title)}
        popupSubtitle={subtitle}
        currentCountryCode={t(country)}
        qrDescription={t(qr_description)}
        storeLinks={store_links}
        qrTitle={t(qr_title)}
      />
    </Popup>
  )
}