import React from 'react'
import { Header as PageHeader } from '../../common/header'
import './index.sass'
import { graphql, useStaticQuery } from "gatsby";
import { SignupForm } from "../signup-form/signup-form";
import { switchMarket } from '../../../services/switch-market';
import { windowGlobal } from '../../../utils/window-global';
import { getResolvedLanguage } from "../../../services/get-language";

export const SPSignup = ({ country, slug }) => {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            country
            slug
            main_title
            secondary_title
            form_title
            form_subtitle
            form_underline_text
            email_required_message
            email_title
            click_link_to_restore_password
            email_already_registered
            reset_your_password
            check_email
            download_app_title
            download_app_subtitle
            actions {
              title
            }
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    const path = windowGlobal.location.pathname.slice(1)
    const slashIndex = path.indexOf('/')
    const pathMarket = path.slice(0, slashIndex)
    if (pathMarket !== country) {
      switchMarket(pathMarket, getResolvedLanguage())
    }
  }, [])

  const data = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter

  return (
    <div className="signup__container container">
      <PageHeader buttonColor="#7767E4" country={country} />
      <SignupForm
        formTitle={data.form_title}
        formSubtitle={data.form_subtitle}
        emailRequiredMessage={data.email_required_message}
        emailTitle={data.email_title}
        emailAlreadyRegisteredText={data.email_already_registered}
        resetPasswordText={data.reset_your_password}
        checkEmail={data.check_email}
        downloadAppTitle={data.download_app_title}
        downloadAppSubtitle={data.download_app_subtitle}
      />
    </div>
  )
}