import { countryCodes } from "./countries";
import { windowGlobal } from "../utils/window-global";
import {getCountryCode, getCountryCodeFromUrl} from "../services/get-country-with-language";

const apiHosts = {
    [countryCodes.UAE]: 'https://api.ogram.co',
    [countryCodes.GREECE]: 'https://api-gr.ogram.co',
    [countryCodes.SA]: 'https://api-sa.ogram.co',
}

export const appLinks = {
    [countryCodes.UAE]: 'https://url.ogram.co/uae-app',
    [countryCodes.GREECE]: 'https://url.ogram.co/gr-app',
    [countryCodes.SA]: 'https://url.ogram.co/ksa-app',
}

export const Settings = {
    apiHost: windowGlobal
        ? apiHosts[getCountryCodeFromUrl()]
        : apiHosts[countryCodes.UAE]
}